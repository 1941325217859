@use '@angular/material' as mat;

@mixin header-theme($theme) {

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .header-panel {
        background-color: mat.get-color-from-palette($primary);
    }

    .app-header {
        > a {
            color: mat.get-color-from-palette($primary, '500-contrast');
        }
    }

    .menu-icon {
        color: mat.get-color-from-palette($primary, '500-contrast');
    }

    .active-menu {
        --mdc-theme-text-primary-on-background: #{mat.get-color-from-palette($primary)};
    }

    .logged-user {
        background-color: mat.get-color-from-palette($accent);

        #firstname {
            color: mat.get-color-from-palette($accent, '500-contrast');
        }
    }

    .language-select:hover {
        background-color: mat.get-color-from-palette($warn);
    }

    .impersonate-warn {
        background-color: mat.get-color-from-palette($accent);
        p {
            color: mat.get-color-from-palette($primary, '500-contrast');
        }
    }

    .spurado-light-text {
        color: mat.get-color-from-palette($primary, '500-contrast');
    }
}
