@use '@angular/material' as mat;

@mixin spurado-footer($theme) {

    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    footer {
        background-color: mat.get-color-from-palette($primary);
    }

    button[mat-button].color-contrast {
        color: mat.get-color-from-palette($primary, '500-contrast');
    }
}
