@use 'node_modules/@angular/material/index' as mat;

@mixin approval-card($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .attribute{
        color: mat.get-color-from-palette($primary);
    }

    mat-card-title{
        color: mat.get-color-from-palette($primary);
    }

    .hover:hover {
        color: mat.get-color-from-palette($accent);
     }

    .hover .icon{
        color: mat.get-color-from-palette($primary);
    }

}
