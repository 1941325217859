@use '@angular/material' as mat;

$mat-konekto-cyan: (
    50 : #e7fafe,
    100 : #c2f2fe,
    200 : #99eafd,
    300 : #70e1fc,
    400 : #52dafb,
    500 : #33d4fa,
    600 : #2ecff9,
    700 : #27c9f9,
    800 : #20c3f8,
    900 : #14b9f6,
    A100 : #ffffff,
    A200 : #f2fbff,
    A400 : #bfecff,
    A700 : #a6e5ff,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: rgba(black, 0.87),
        A400: rgba(black, 0.87),
        A700: rgba(black, 0.87),
    )
);

$mat-konekto-dark-blue: (
    50 : #e1e1ec,
    100 : #b5b5d0,
    200 : #8484b1,
    300 : #525292,
    400 : #2d2d7a,
    500 : #080863,
    600 : #07075b,
    700 : #060651,
    800 : #040447,
    900 : #020235,
    A100 : #6d6dff,
    A200 : #3a3aff,
    A400 : #0707ff,
    A700 : #0000ec,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: white,
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: rgba(black, 0.87),
        A200: white,
        A400: white,
        A700: white,
    )
);

$mat-konekto-violet: (
    50 : #efe9fd,
    100 : #d7c7fb,
    200 : #bca2f9,
    300 : #a17df6,
    400 : #8c61f4,
    500 : #7845f2,
    600 : #703ef0,
    700 : #6536ee,
    800 : #5b2eec,
    900 : #481fe8,
    A100 : #ffffff,
    A200 : #efebff,
    A400 : #c5b8ff,
    A700 : #b09fff,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
        A100: white,
        A200: white,
        A400: white,
        A700: white,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$spurado-app-primary: mat.define-palette($mat-konekto-violet);
$spurado-app-accent: mat.define-palette($mat-konekto-dark-blue);

// The warn palette is optional (defaults to red).
$spurado-app-warn: mat.define-palette($mat-konekto-cyan);

// Create the theme object (a Sass map containing all of the palettes).
$spurado-app-theme: mat.define-light-theme((
    color: (
        primary: $spurado-app-primary,
        accent: $spurado-app-accent,
        warn: $spurado-app-warn
    )
));

$primary: mat.get-color-from-palette($spurado-app-primary);
$accent: mat.get-color-from-palette($spurado-app-accent);
$warn: mat.get-color-from-palette($spurado-app-warn);
$background: map-get($spurado-app-theme, background);
$foreground: map-get($spurado-app-theme, foreground);
$default-text-color: white;

/*@mixin custom-theme($theme) {
    // Include all the custom component style to be built
    @include spurado-main-style($theme);
    @include spurado-table($theme);
    @include spurado-messages($theme);
    @include spurado-admin($theme);
    @include spurado-validation-table($theme);
}*/

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($spurado-app-theme);
/*@include custom-theme($spurado-app-theme);*/
