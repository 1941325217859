@use '@angular/material' as mat;

@mixin spurado-main-style($theme) {

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    & {
        --color-accent: #{mat.get-color-from-palette($accent)};
        --color-primary: #{mat.get-color-from-palette($primary)};
        --color-on-accent: #{mat.get-color-from-palette($accent, default-contrast)};
    }

    .spurado-main-title {
        margin-bottom: 2rem;
        width: 100%;
        height: 4rem;
        line-height: 4rem;
        background-color: mat.get-color-from-palette($primary, 50);
        color: mat.get-color-from-palette($primary, '50-contrast');

        h1 {
            font-size: 1.2rem;
            line-height: 4rem;
            width: 90%;
            margin: auto;
        }

        .spurado-main-description {

        }
    }

    div.spurado-no-action-panel {
        margin: auto;
        color: mat.get-color-from-palette($primary, 700);
        text-align: center;

        h1 {
            padding: 2rem 0;
            font-size: 2rem;
        }

        mat-icon {
            color: mat.get-color-from-palette($warn, 700);
            font-size: 5rem;
            width: unset;
            height: unset;
        }
    }

    .notification-info {
        color: #004085;
        background-color: #cce5ff;
        border-color: #b8daff;
    }

    .notification-warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }

    .notification-error {
        color: mat.get-color-from-palette($warn, '500-contrast');
        background-color: mat.get-color-from-palette($warn);
        border-color: #f5c6cb;

        button {
            background-color: mat.get-color-from-palette($warn, 200);
            color: mat.get-color-from-palette($warn, '200-contrast');
            --mat-mdc-snack-bar-button-color: mat.get-color-from-palette($warn, '200-contrast');
        }
    }

    #personal-holiday-chip .personal-holiday-duration-icon {
        margin-left: 7px !important;
        vertical-align: -7px;
        font-size: 23px !important;
        transform: rotate(90deg);
    }

    .status-helper {
        font-size: 0.8em;
        color: mat.get-color-from-palette($primary);
        font-weight: bold;
        vertical-align: 6px;
    }

    .spurado-scroller {
        max-height: 305px;
        overflow-y: auto;
        scrollbar-color: mat.get-color-from-palette($primary) transparent;
        scrollbar-width: thin;
    }

    .spurado-scroller::-webkit-scrollbar-thumb {
        background-color: mat.get-color-from-palette($primary);
        border-radius: 4px;
    }

    .success {
        color: mat.get-color-from-palette($primary);
    }

    .success-background {
        background-color: mat.get-color-from-palette($primary);
    }

    .warning {
        color: mat.get-color-from-palette($accent);
    }

    .warning-background {
        background-color: mat.get-color-from-palette($accent);
    }

    .error {
        color: mat.get-color-from-palette($warn);
    }

    .error-background {
        background-color: mat.get-color-from-palette($warn);
    }

    .soft-background-primary:hover {
        background-color: mat.get-color-from-palette($primary, 50) !important;
    }

    .soft-background-accent:hover {
        background-color: mat.get-color-from-palette($accent, 50) !important;
    }

    .mat-chip {
        background-color: mat.get-color-from-palette($primary, 700) !important;
        color: mat.get-color-from-palette($primary, '700-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($primary, '700-contrast') !important;
    }

    .primary-700 {
        background-color: mat.get-color-from-palette($primary, 700) !important;
        color: mat.get-color-from-palette($primary, '700-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($primary, '700-contrast') !important;
    }

    .primary-100 {
        background-color: mat.get-color-from-palette($primary, 100) !important;
        color: mat.get-color-from-palette($primary, '100-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($primary, '100-contrast') !important;
    }

    .accent-700 {
        background-color: mat.get-color-from-palette($accent, 700) !important;
        color: mat.get-color-from-palette($accent, '700-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($accent, '700-contrast') !important;
    }

    .accent-200 {
        background-color: mat.get-color-from-palette($accent, 100) !important;
        color: mat.get-color-from-palette($accent, '100-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($accent, '100-contrast') !important;
    }

    .warn-700 {
        background-color: mat.get-color-from-palette($warn, 700) !important;
        color: mat.get-color-from-palette($warn, '700-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($warn, '700-contrast') !important;
    }

    .warn-100 {
        background-color: mat.get-color-from-palette($warn, 100) !important;
        color: mat.get-color-from-palette($warn, '100-contrast') !important;
        --mdc-chip-label-text-color: mat.get-color-from-palette($warn, '100-contrast') !important;
    }

}
