@use 'node_modules/@angular/material/index' as mat;

@mixin approval-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .danger{
        color: mat.get-color-from-palette($warn);
    }

}

mat-icon{
    margin: 0;
}
