@use '../../../../../node_modules/@angular/material/index' as mat;

@mixin spurado-table($theme) {

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .day-name {
        text-transform: capitalize;
    }

    .highlightedTask {
        border: 1px dashed mat.get-color-from-palette($primary);
    }

    .table-row:first-child {
        padding-bottom: 0.5rem;
        margin-bottom: 0.1rem;
        color: mat.get-color-from-palette($primary, 500);
        font-size: 1.2rem;
        border-bottom: 2px solid mat.get-color-from-palette($primary, 500);
    }

    .table-row:last-child {
        padding-top: 0.5rem;
        margin-top: 0.1rem;
        color: mat.get-color-from-palette($primary, 500);
        font-size: 1.2rem;
        border-top: 2px solid mat.get-color-from-palette($primary, 500);
    }

    .week-end {
        background-color: rgba(206, 206, 206, 0.2);
    }

    .mobile-personal-holiday {
        border-left: 5px solid mat.get-color-from-palette($accent, 500);
    }

    .mobile-legal-holiday {
        border-left: 5px solid mat.get-color-from-palette($primary, 500);
    }

    .legal-holiday {
        background-color: rgba(206, 206, 206, 0.2);
    }

    .personal-holiday {
        background-color: mat.get-color-from-palette($accent, 50);
    }

    .action-buttons {
        margin: 50px 0;
        text-align: center;

        h1 {
            padding-bottom: 4rem;
        }

        button {
            margin: 0 10px;
        }
    }
}
