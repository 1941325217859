@use 'node_modules/@angular/material/index' as mat;

@mixin spurado-admin($theme) {

    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .admin-item {
        color: mat.get-color-from-palette($primary, 0.8);
        border: 1px solid mat.get-color-from-palette($primary, 1);
        text-decoration: none;
        border-radius: 4px;
        text-align: center;
        margin: 1rem;
        padding: 1rem;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 175px;
    }

    .admin-item:hover {
        background-color: mat.get-color-from-palette($primary, 50);
    }
}
