@use 'node_modules/@angular/material/index' as mat;

@mixin search-approval-filter-theme-card($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .search{
        color: mat.get-color-from-palette($primary);
    }

    #result {
        scrollbar-face-color: mat.get-color-from-palette($primary);
    }
}
