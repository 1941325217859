@use '@angular/material' as mat;
//https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
mat.$theme-ignore-duplication-warnings: true;
@import 'assets/scss/spurado-main-style';
@import 'assets/themes/components-theme';
@import 'assets/themes/spurado-angular-theme';
@import 'assets/themes/icity-angular-theme';
@import 'assets/themes/wib-angular-theme';
@import 'assets/themes/neutral-angular-theme';
@import url('https://fonts.googleapis.com/css?family=Quicksand|Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp&display=swap');

// Define a custom typography config that overrides the font-family
$konekto-typography: mat.define-typography-config(
    $font-family: 'Quicksand, sans-serif',
);
@include mat.typography-hierarchy($konekto-typography);
@include mat.all-component-typographies($konekto-typography);
@include mat.core();
@include mat.core-typography($konekto-typography);

.spurado-theme {
    @include spurado-main-style($spurado-app-theme);
    @include header-theme($spurado-app-theme);
    @include spurado-footer($spurado-app-theme);
    @include admin-navigation-theme($spurado-app-theme);
    @include spurado-table($spurado-app-theme);
    @include spurado-messages($spurado-app-theme);
    @include spurado-admin($spurado-app-theme);
    @include spurado-validation-table($spurado-app-theme);
    @include spurado-chip($spurado-app-theme);
    @include timesheet-pdf-generation-theme($spurado-app-theme);
    @include mat.all-component-themes($spurado-app-theme);
    @include stat-tile($spurado-app-theme);
    @include approval-card($spurado-app-theme);
    @include search-approval-filter-theme-card($spurado-app-theme);
    @include approval-theme($spurado-app-theme);
    @include admin-timesheet-theme($spurado-app-theme);
}

.icity-theme {
    @include spurado-main-style($icity-app-theme);
    @include header-theme($icity-app-theme);
    @include spurado-footer($icity-app-theme);
    @include admin-navigation-theme($icity-app-theme);
    @include spurado-table($icity-app-theme);
    @include spurado-messages($icity-app-theme);
    @include spurado-admin($icity-app-theme);
    @include spurado-validation-table($icity-app-theme);
    @include spurado-chip($icity-app-theme);
    @include timesheet-pdf-generation-theme($icity-app-theme);
    @include mat.all-component-themes($icity-app-theme);
    @include stat-tile($icity-app-theme);
    @include approval-card($icity-app-theme);
    @include search-approval-filter-theme-card($icity-app-theme);
    @include approval-theme($icity-app-theme);
    @include admin-timesheet-theme($icity-app-theme);
}

.wib-theme {
    @include spurado-main-style($wib-app-theme);
    @include header-theme($wib-app-theme);
    @include spurado-footer($wib-app-theme);
    @include admin-navigation-theme($wib-app-theme);
    @include spurado-table($wib-app-theme);
    @include spurado-messages($wib-app-theme);
    @include spurado-admin($wib-app-theme);
    @include spurado-validation-table($wib-app-theme);
    @include spurado-chip($wib-app-theme);
    @include timesheet-pdf-generation-theme($wib-app-theme);
    @include mat.all-component-themes($wib-app-theme);
    @include stat-tile($wib-app-theme);
    @include approval-card($wib-app-theme);
    @include search-approval-filter-theme-card($wib-app-theme);
    @include approval-theme($wib-app-theme);
    @include admin-timesheet-theme($wib-app-theme);
}

.neutral-theme {
    @include spurado-main-style($neutral-app-theme);
    @include header-theme($neutral-app-theme);
    @include spurado-footer($neutral-app-theme);
    @include admin-navigation-theme($neutral-app-theme);
    @include spurado-table($neutral-app-theme);
    @include spurado-messages($neutral-app-theme);
    @include spurado-admin($neutral-app-theme);
    @include spurado-validation-table($neutral-app-theme);
    @include spurado-chip($neutral-app-theme);
    @include timesheet-pdf-generation-theme($neutral-app-theme);
    @include mat.all-component-themes($neutral-app-theme);
    @include stat-tile($neutral-app-theme);
    @include approval-card($neutral-app-theme);
    @include search-approval-filter-theme-card($neutral-app-theme);
    @include approval-theme($neutral-app-theme);
    @include admin-timesheet-theme($neutral-app-theme);
}



:root {
    font-size: 16px;
    box-sizing: border-box;
}

html, body {
    height: 100%;
}

body {
    margin: 0;
}

.dialog-container {
    mat-dialog-container {
        padding: 0;
    }
}

.container {
    margin-top: 0.5rem;
    padding: 1rem;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    box-sizing: border-box;
}

.mat-menu-content {
    padding-top: 0 !important;
}

.lang-menu > .mat-menu-content {
    padding-bottom: 0 !important;
}

div.mat-form-field-infix {
    width: auto;
}

.component-content h1.mat-headline {
    margin: 2rem 0;
}

.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    margin: auto auto;
}

.full-screen-loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader-content {
    width: 100px;
    height: 100px;
    margin: auto auto;
}

.spurado-light-text {
    //color: $light-primary-text;
}

.spurado-action-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 2;

    mat-slide-toggle {
        margin-right: 2rem;
    }

    mat-form-field {
        margin-right: 2rem;
    }

    button {
        margin-bottom: 1.25rem; /* align the tables if there's no filter */
    }
}

.spurado-scroller::-webkit-scrollbar {
    width: 0.5rem;
}

.spurado-scroller::-webkit-scrollbar-track {
    background-color: transparent;
}

.component-content {
    width: 90%;
    margin: 2rem auto;
}

.flex-row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


mat-form-field.transparent * {
    background-color: transparent !important;
}

mat-form-field.no-radius .mat-mdc-text-field-wrapper {
    border-radius: 0;
}

.flex-row-space-evenly {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.override-input-text-width > div.mat-mdc-text-field-wrapper > div.mat-mdc-form-field-flex > div.mat-mdc-form-field-infix {
    width: auto;
}

.spinner {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
}

@media (max-width: 600px) {
    div.spurado-no-action-panel {
        h1 {
            font-size: 1.5rem;
        }

        mat-icon {
            font-size: 3.5rem;
            width: unset;
            height: unset;
        }
    }
}


/**
    Configuration pour les icônes
 */

.material-icons-outlined,
.material-icons.material-icons--outlined,
.material-icons-two-tone,
.material-icons.material-icons--two-tone,
.material-icons-round,
.material-icons.material-icons--round,
.material-icons-sharp,
.material-icons.material-icons--sharp {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    -moz-user-select: none;
    //-khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.material-icons-outlined,
.material-icons.material-icons--outlined {
    font-family: 'Material Icons Outlined';
}

.material-icons-two-tone,
.material-icons.material-icons--two-tone {
    font-family: 'Material Icons Two Tone';
}

.material-icons-round,
.material-icons.material-icons--round {
    font-family: 'Material Icons Round';
}

.material-icons-sharp,
.material-icons.material-icons--sharp {
    font-family: 'Material Icons Sharp';
}

th.center-flex .mat-sort-header-container {
    justify-content: center;
}

mat-icon.tooltip-icon {
    font-size: 0.8rem;
    vertical-align: middle;
    margin-left: 3px;
}

@media only screen and (max-width: 1053px) {
    :root {
        font-size: 14px;
    }

    .mat-dialog-container {
        max-width: 80vw !important;
    }
}

.comment-dialog {
    mat-dialog-container {
        min-width: 500px;
        max-width: 100%;
    }
}

@media screen and (max-width: 980px) {
    .comment-dialog {
        width: 100%;
        min-width: 250px;

        mat-dialog-container {
            min-width: 250px;
        }
    }
}

