@use '@angular/material' as mat;

@mixin spurado-messages($theme) {

    $primary: map-get($theme, primary);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .message-wrapper {
        transform: translate(-4rem, -4rem);
        position: absolute;
        right: 0;
        bottom: 0;
        border-radius: 4px;
        background-color: mat.get-color-from-palette($warn, A400);
        border: 2px solid mat.get-color-from-palette($warn, 900);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0px rgba(0, 0, 0, 0.12);
        padding: 20px;
        z-index: 1000;
        max-width: 30%;
        overflow: auto;

        .info-message {
            max-height: 0;
            width: 0;
            transition: max-height 1s ease, width 1s ease;
        }
    }

    .message-wrapper:hover {
        cursor: pointer;
        background-color: mat.get-color-from-palette($warn, 100);
    }

    .message-wrapper.expended #main-message-alert .info-message {
        transition: max-height 1s ease, width 1s ease;
        max-height: 250px;
        max-width: 30%;
    }

    .message-wrapper.expended {

        #main-message-alert {
            > div {
                opacity: 0;
                transition: opacity 1s ease;
            }
        }

        .info-message {
            transition: max-height 1s ease, width 1s ease;
            max-width: 30%;
        }
    }

    .message-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: mat.get-color-from-palette($warn, 'A400-contrast');
        font-weight: lighter;
    }
}
