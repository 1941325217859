@use '@angular/material' as mat;

@mixin spurado-chip($theme) {

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    .chip-wrapper {
        position: relative;
        margin: 4px;
        font-size: 0.8rem;
    }

    .chip {
        color: rgba(black, 0.87);
        padding: 0.3rem 0.8rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        border-radius: 4px;
        user-select: none;

        div {
            display: flex;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        mat-icon {
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }

    .chip-color-primary {
        .chip {
            border: 1px solid mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, '50-contrast');
        }

        .chip-progress {
            background-color: mat.get-color-from-palette($primary, 300);
        }

        .selected {
            background-color: mat.get-color-from-palette($primary, 50);
        }
    }

    .chip-color-accent {
        .chip {
            border: 1px solid mat.get-color-from-palette($accent);
            color: mat.get-color-from-palette($accent, '50-contrast');
        }

        .chip-progress {
            background-color: mat.get-color-from-palette($accent, 300);
        }

        .selected {
            background-color: mat.get-color-from-palette($accent, 50);
        }
    }

    .chip-color-warn {
        .chip {
            border: 1px solid mat.get-color-from-palette($warn);
            color: mat.get-color-from-palette($warn, '50-contrast');
        }

        .chip-progress {
            background-color: mat.get-color-from-palette($warn, 300);
        }

        .selected {
            background-color: mat.get-color-from-palette($warn, 50);
        }
    }

    .chip-progress {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 3px;
        animation: width 1s ease-in-out;
    }

    .chip-50-start {
        width: 50%;
        border-radius: 0 0 0 4px;
    }

    .chip-50-end {
        left: 50%;
        width: 50%;
        border-radius: 0 0 4px 0;
    }

    .chip-100 {
        width: 100%;
        border-radius: 0 0 4px 4px;
    }
}
