@use '../../../../node_modules/@angular/material/index' as mat;

@mixin spurado-validation-table($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    #grid-container {
        flex: 3;
        margin: 1rem;
        font-size: 1.2rem;
        display: grid;
        grid-template-columns: auto auto;
        grid-auto-rows: 2rem;
        align-items: center;

        > div {
            margin: 5px;
            line-height: 2rem;
        }

        .grid-header {
            width: 100%;
            height: 2rem;
            background-color: mat.get-color-from-palette($primary, 50);
            color: mat.get-color-from-palette($primary, '50-contrast');
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .grid-column-1 {
            height: 2rem;
            position: relative;
            text-transform: capitalize;
            color: mat.get-color-from-palette($primary, 500);

            > div:first-child {
                padding-left: 10px;
                display: flex;
                justify-content: space-between;
            }
        }
    }

    .sliding-container {
        font-size: 1rem;
        color: white;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: mat.get-color-from-palette($accent, 500);
        overflow: hidden;
        width: 4px;
        height: 100%;
        line-height: 2rem;
        text-align: center;
        transition: .75s ease width, .75s ease border-radius;
        padding-left: 4px;
    }

    .grid-column-1:active {
        user-select: none;

        .sliding-container {
            border-top-right-radius: 1rem;
            border-bottom-right-radius: 1rem;
            width: 100%;
        }
    }

    #grid-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #grid-actions {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
}

ul{
    list-style: none;
}

ul li{
    display: inline-block;
}
