@use '../../../node_modules/@angular/material/index' as mat;

@mixin timesheet-pdf-generation-theme($theme) {

    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .primary-color {
        color: mat.get-color-from-palette($primary);
    }

    .accent-color {
        color: mat.get-color-from-palette($accent);
    }
}
