@use 'node_modules/@angular/material/index' as mat;

@mixin stat-tile($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);

    .primary-square {
        background-color: mat.get-color-from-palette($primary, 50);
        color: mat.get-color-from-palette($primary, '50-contrast');
        border: 2px solid mat.get-color-from-palette($primary);
        text-align: center;
        width: 150px;
        height: 110px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .warn-square {
        background-color: mat.get-color-from-palette($warn, 50);
        color: mat.get-color-from-palette($warn, '50-contrast');
        border: 2px solid mat.get-color-from-palette($warn);
        text-align: center;
        width: 150px;
        height: 110px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .accent-square {
        background-color: mat.get-color-from-palette($accent, 50);
        color: mat.get-color-from-palette($accent, '50-contrast');
        border: 2px solid mat.get-color-from-palette($accent);
        text-align: center;
        width: 150px;
        height: 110px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .primary-w-240 {
        color: mat.get-color-from-palette($primary);
        border: 2px solid mat.get-color-from-palette($primary);
        text-align: center;
        max-width: 48%;
        height: 115px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .warn-w-240 {
        color: mat.get-color-from-palette($warn);
        border: 2px solid mat.get-color-from-palette($warn);
        text-align: center;
        max-width: 48%;
        height: 115px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .accent-w-240 {
        color: mat.get-color-from-palette($accent);
        border: 2px solid mat.get-color-from-palette($accent);
        text-align: center;
        max-width: 48%;
        height: 115px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .primary-w-auto {
        color: mat.get-color-from-palette($primary);
        text-align: center;
        height: 100px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .warn-w-auto {
        color: mat.get-color-from-palette($warn);
        border: 2px solid mat.get-color-from-palette($warn);
        text-align: center;
        height: 100px;
        overflow: hidden;
        border-radius: 1rem;
    }

    .accent-w-auto {

        color: mat.get-color-from-palette($accent);
        border: 2px solid mat.get-color-from-palette($accent);
        text-align: center;
        height: 100px;
        overflow: hidden;
        border-radius: 1rem;
    }
}
